.container {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  display: flex;
  align-items: center;
  gap: 1em;
}

.index {
  color: gray;
}

.links {
  display: flex;
  flex-direction: row;
  gap: 1ch;
}
