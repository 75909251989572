.app {
  display: flex;
  flex-direction: column;
  margin-left: 2em;
  margin-right: 2em;
  align-items: center;
}

.questionsContainer {
  display: flex;
  flex-direction: column;
  gap: 3em;
  max-width: 30em;
}

.solutionContainer {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  border-radius: 0.5em;
  box-shadow: 0 1em 2em 0 rgba(0, 0, 0, 0.2);
}
