.answerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.answerText {
  font-family: monospace;
  word-wrap: break-word;
}

.answerButton {
  color: white;
  background-color: black;
  font-size: larger;
  padding: 0.5rem;
  border-style: none;
  border-radius: 0.5em;
  transition: 100ms;
}

.answerButton:active {
  transform: scale(0.99);
}
